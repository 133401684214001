import { Button, Col, Container, Row } from 'react-bootstrap';

import React from 'react';
import ashlee from '../../images/webinar/ashlee.png';
import webinarIcon from '../../images/webinar/webinar-icon.svg';

const MeetHost = () => {
  return (
    <section className='common-section webinar-host-section px-4'>
      <Container fluid='lg'>
        <Row className='align-items-center text-center'>
          <Col xs={12}>
            <h2 className='pb-4 py-sm-0'>
              New to Medicare? Watch our FREE Webinar
            </h2>
            <p className='p16'>
              Join us online for our free comprehensive webinar to explore the
              complexities of Medicare. During our 60-minute presentation, you
              will gain valuable insights on how to navigate your health
              insurance when you are new to Medicare, from the comfort of your
              own home. This on-demand webinar is available to watch from any
              smart phone, tablet, or computer now.
            </p>
          </Col>
        </Row>
        <Row className='mt20'>
          <Col sm={8} xs={12} className='order-sm-2'>
            <h2 className='mb0'>Meet Your Host</h2>
            <p className='font-italic p16 pb-4 mb-2'>
              Ashlee Zareczny, Compliance Manager at MedicareFAQ.com
            </p>
            <p className='p16'>
              Ashley Zareczny, Compliance Manager at MedicareFAQ.com, is one of
              the original team members at MedicareFAQ.com. She has held several
              roles with the company and offers a broad knowledge of all things
              Medicare. With a strong commitment to providing the most accurate
              Medicare information to our viewers, Ashlee goes the extra mile to
              ensure all information she provides is well vetted and complies
              with various standards.
            </p>
            <p className='p16'>
              In pursuit of her passion for improving Medicare transparency,
              Ashlee holds a bachelor’s degree in business management and has
              several years of experience working in the Medicare health
              insurance industry. Armed with this knowledge, she is passionate
              about educating others and delivering high-quality information to
              our clients.
            </p>
          </Col>
          <Col sm={4} xs={12} className='order-sm-1 centered-element'>
            <img src={ashlee} style={{ width: '100%', maxWidth: '320px' }} />
          </Col>
        </Row>
        <Row className='mt20'>
          <Col sm={8} xs={12} className='order-sm-2'>
            <h2 className='pb-4 mb-0'>
              Join Medicare Specialist Ashlee Zareczny
            </h2>
            <p className='p16 text-left pb-2'>
              In this webinar presentation we will go over:
            </p>
            <p className='p16'>
              <ul style={{ marginLeft: '20px', textAlign: 'initial' }}>
                <li>The basics of Medicare</li>
                <li>How to enroll in Medicare</li>
                <li>Medicare partsand their costs</li>
                <li>Common Medicare mistakes to avoid</li>
                <li>
                  How to get free claim assistance for your policy... and more!
                </li>
              </ul>
            </p>
            <p className='p16'>
              <span className='font-weight-bold'>IMPORTANT:</span> This webinar
              does not provide information on government retiree benefits or
              Medicaid.
            </p>
          </Col>
          <Col sm={4} xs={12} className='order-sm-1 centered-element'>
            <img
              src={webinarIcon}
              style={{ width: '100%', maxWidth: '160px' }}
            />
            <div className='webinar-btn-wrapper mt-4'>
              <span
                className='demio-embed-registration'
                data-hash='55RMmRnA1Ldby6zE'
                data-api='api/v1'
                data-base-uri='https://my.demio.com/'
                data-popover='true'
                data-button-width='100%'
                data-button-size='medium'
                data-button-text='REGISTER TODAY!'
                data-link-text='My Popover Link'
                data-color='#ba0000'
                data-text='REGISTER TODAY!'
              ></span>
            </div>
            <span className='mt-4'>Duration: 60 minutes</span>
            <span>Q&A to follow</span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MeetHost;
