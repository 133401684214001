import { Col, Container, Row } from 'react-bootstrap';

import React from 'react';

const HeroSection = () => {
  return (
    <section className='common-section webinar-hero-section'>
      <Container fluid='lg'>
        <Row>
          <Col xs={12} sm={6} className='header px-4'>
            <div>
              <h2>Intro to Medicare Webinar</h2>
              <p>
                Join our free online webinar today! Gain valuable insights on
                the Medicare health insurance program and discover everything
                you need to know about navigating your healthcare.
              </p>
              <p className='mt-4'>60-minute presentation with Q&A to follow.</p>
            </div>
          </Col>
          <Col xs={12} sm={6} style={{ padding: '0' }}>
            <div className='hero-form-wrapper px-4'>
              <h2>Intro to Medicare Webinar</h2>
              <p>Reserve Your Spot Today!</p>
              <span
                className='demio-embed-registration'
                data-hash='55RMmRnA1Ldby6zE'
                data-api='api/v1'
                data-base-uri='https://my.demio.com/'
                data-form-width='100%'
                data-color='#ba0000'
                data-text='REGISTER'
              ></span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
