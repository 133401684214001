import DownloadPdf from '../../components/webinar/downloadPdf';
import { Helmet } from 'react-helmet';
import HeroSection from '../../components/webinar/heroSection';
import Layout from '../../components/layout';
import MeetHost from '../../components/webinar/meetHost';
import React from 'react';
import TestimonialSection from '../../components/webinar/testimonialSection';

const index = () => {
  return (
    <Layout header footer stickyFooter={false}>
      <Helmet>
        <title>Intro to Medicare Webinar</title>
        <meta name='og:description' content='Intro to Medicare Webinar' />
        <meta name='description' content='Intro to Medicare Webinar' />
        <meta name='og:title' content='Intro to Medicare Webinar' />
        <meta name='title' content='Intro to Medicare Webinar' />
      </Helmet>
      <HeroSection />
      <MeetHost />
      <DownloadPdf />
      <TestimonialSection />
    </Layout>
  );
};

export default index;
