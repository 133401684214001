import { Col, Container, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HaveQuestions from './haveQuestions';
import React from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const testimonials = [
  {
    message:
      'I switched to the same plan with the exact same coverage and I saved $753 a year by comparing rates with MedicareFAQ! My wife will be turning 65 next year, MedicareFAQ will have her business as well as our family and friends. Top notch company with extremely knowledgeable agents.',
    clientName: 'Tom R.',
    rating: 5,
  },
  {
    message:
      'Trying to understand the process of Medicare A & B, Supplemental, and Advantage plans can get so overwhelming. My agent explained in detail to help me understand my options. A lot of stress was relieved today. I will be ready when my plan kicks in. Thank You!',
    clientName: 'Patricia S.',
    rating: 5,
  },
  {
    message:
      "I had done my medicare homework but having [the agent] go thru things with me was comforting. When coming to medicare it's confusing but the team gave me the choices that made things seamless. They also gave me a few follow up calls making me know I made the right call. Thanks [agent] and team.",
    clientName: 'Brad B.',
    rating: 5,
  },
  {
    message:
      'Friendly people to do business with. Called me back at a time when I could answer the phone and talk when not at work. Listened to my questions, gave me some options and helped me pick the best policies for me and my budget.',
    clientName: 'Eric L.',
    rating: 5,
  },
];

const TestimonialSection = () => {
  return (
    <div
      style={{
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.0001) -31.9%, #E8EEFF 100%)',
      }}
    >
      <section className='common-section webinar-testimonial'>
        <Container fluid='lg'>
          <Row className='align-items-center text-center'>
            <Col xs={12}>
              <h2 className='pb-4'>What People Are Saying</h2>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            {testimonials.map((testimonial, index) => {
              return (
                <Col xs={12} sm={5} className='webinar-card' key={index}>
                  <div className='star-wrap'>
                    {[...Array(testimonial.rating)].map((e, i) => {
                      return (
                        <FontAwesomeIcon
                          key={i}
                          className='text-yellow'
                          icon={faStar}
                          style={{
                            width: '28px',
                            height: '28px',
                            marginRight: '5px',
                          }}
                        />
                      );
                    })}
                  </div>
                  <p>{testimonial.message}</p>
                  <p style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    -{' '}
                    <span style={{ color: '#222C69', fontWeight: '600' }}>
                      {testimonial.clientName}
                    </span>
                  </p>
                </Col>
              );
            })}
          </Row>
        </Container>
        <HaveQuestions />
      </section>
    </div>
  );
};

export default TestimonialSection;
