import { Col, Container, Row } from 'react-bootstrap';

import React from 'react';
import m101Booklet from '../../images/webinar/m101Booklet.png';
import m101Bookletpdf from '../../static/medicare101booklet.pdf';

const DownloadPdf = () => {
  return (
    <section className='common-section webinar-download-pdf-section px-4 py-4'>
      <Container fluid='lg'>
        <Row>
          <Col sm={4} xs={12} className='order-sm-2'>
            <img src={m101Booklet} className='booklet-positioning ' />
          </Col>
          <Col sm={8} xs={12} className='order-sm-1 download-content'>
            <div>
              <p>
                Our FREE online Medicare 101 guide reviews all the Medicare
                coverage options available to Medicare enrollees. Download our
                guide for information on finding the right coverage for your
                needs and making informed decisions for your health and budget.
              </p>
              <a
                tabIndex='0'
                className='keyboard-highlight btn webinar-btn webinar-download-btn py-2 mt-4'
                id='webinar-m101-download'
                href={m101Bookletpdf}
                download='Medicare101.pdf'
              >
                DOWNLOAD
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DownloadPdf;
