import { Button, Col, Container, Row } from 'react-bootstrap';

import React from 'react';
import comparePlan from '../../images/webinar/compare-plans.png';
import contact from '../../images/webinar/contact.png';
import rating from '../../images/webinar/view-rating.png';

const HaveQuestions = () => {
  return (
    <>
      <section className='common-section pb-0'>
        <Container>
          <Row className='align-items-center'>
            <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <p
                style={{
                  height: '4px',
                  width: '150px',
                  background: '#222c69',
                  borderRadius: '4px',
                  padding: '0px',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className='common-section webinar-questions'>
        <Container fluid='lg'>
          <Row className='align-items-center text-center'>
            <Col xs={12}>
              <h2>Still Have Questions?</h2>
              <h3 className='pb-4'>These resources may help:</h3>
            </Col>
          </Row>
          <Row className='mt4 pt-4 align-items-top text-center'>
            <Col xs={12} sm={4}>
              <div className='resource-item'>
                <div className='img-wrap'>
                  <img src={contact} alt='lets talk' />
                </div>
                <div>
                  <h3>Let’s Talk</h3>
                  <p>
                    Our team of licensed insurance agents can help answer any
                    questions you may have.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4} className='text-center'>
              <div className='resource-item'>
                <div className='img-wrap'>
                  <img src={comparePlan} alt='compare plans' />
                </div>
                <div>
                  <h3>Compare Plans</h3>
                  <p>
                    Our quoting tool helps compare the top plans and carriers in
                    your area.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className='resource-item'>
                <div className='img-wrap'>
                  <img src={rating} alt='view rating' />
                </div>
                <div>
                  <h3>View Rating</h3>
                  <p>
                    Read our reviews to see what people are saying about us.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center text-center pt-4'>
            <Col xs={12}>
              <h3>
                Let us walk you through your Medicare journey.{' '}
                <span className='d-sm-block'>
                  Sign up for our Webinar today.
                </span>
              </h3>
              <div className='webinar-btn-wrapper mt-4'>
                <span
                  className='demio-embed-registration'
                  data-hash='55RMmRnA1Ldby6zE'
                  data-api='api/v1'
                  data-base-uri='https://my.demio.com/'
                  data-popover='true'
                  data-button-width='100%'
                  data-button-size='medium'
                  data-button-text='REGISTER TODAY!'
                  data-link-text='My Popover Link'
                  data-color='#ba0000'
                  data-text='REGISTER TODAY!'
                ></span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HaveQuestions;
